import React, { useEffect, Fragment } from "react"
import ImageDarkTextRightWithButton from "../components/imageDarkTextRightWithButton"
import ImageDarkTextLeftWithButton from "../components/imageDarkTextLeftWithButton"
import CTABusiness from "../../../general/ctaBusiness"
import FAQList from '../../../general/faqsList';
import InnerPageHeader from "../components/innerPageHeader"
import { scrollToElement } from "../../../../../utility/utils"
import DarkBelowFoldCards from "../../../general/darkBelowFoldCards";
import MoreForYouComponent from "../../../general/more-for-you";

// hero image
import EntryIllustration from '../../../../../../assets/img/tv_subscription-hero-image.svg'; 
import EntryIllustrationBlur from "../../../../../../assets/img/business/blur/tv_subscription-blur.inline.svg";

import TvIcon from "../../../../../../assets/img/kuda-tv.inline.svg"
import InboxYellowIcon from "../../../../../../assets/img/kuda-inbox-yellow.inline.svg"
import SwitchIcon from '../../../../../../assets/img/kuda-switch-purple.inline.svg'
import serviceProviderIllustration from "../../../../../../assets/img/business/service-provider-illustration.svg"
import tvSubscriptionIllustration from "../../../../../../assets/img/business/tv-subscription-illustration.svg"
import subPackageIllustration from "../../../../../../assets/img/business/subPackage-illustration.svg"

import KudaPOSTerminalIcon from "../../../../../../assets/img/more-for-you/kuda-pos-icon.inline.svg"
import InvoicingIcon from "../../../../../../assets/img/more-for-you/kuda-invoicing.inline.svg"
import BizSalaryLoanIcon from '../../../../../../assets/img/more-for-you/kuda-bizSalaryLoan.icon.inline.svg'

import { Img } from "react-image";


const entryContent = {
  title: (<span>
    <span className="color-secondary">Pay</span> TV subscriptions for your office easily on Kuda Business!

  </span>),
  subtitle: "Watch news, sports and more at work with convenient DStv, Showmax, GOtv and StarTimes payments on Kuda.",
  name: "Download Kuda Business",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
}

const businessTopFeatures = [
  {
    icon: <TvIcon />,
    text: "Pay DStv, Showmax, GOtv and StarTimes subscriptions easily."
  },
  {
    icon: <InboxYellowIcon />,
    text: "Get your subscription token on your Kuda Business app or in your inbox."
  },
  {
    icon: <SwitchIcon />,
    text: "Never switch apps to make your TV payments again."
  },
]

const serviceProvider = {
  title: "Find all major TV service providers.",
  subtitle: "DStv, Showmax, GOtv and StarTimes are all easy-to-find options for you on the Kuda Business mobile and web apps",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
  name: "Download Kuda Business",
  illustration: (
    <Img src={serviceProviderIllustration} className="kuda-business-image" />

  ),
}

const tvSubcription = {
  title: "It takes just minutes to pay your TV subscription.",
  subtitle: "Choose, pay and watch. It’s that simple to sort out your TV subscription on the Kuda Business app.",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
  name: "Join Kuda Business",
  illustration: (

    <Img src={tvSubscriptionIllustration} className="kuda-business-image" />

  ),
}

const subPackage = {
  title: "Find all popular TV subscription packages.",
  subtitle: "Whether you have a favourite TV subscription package or you’re looking for the right one for you, you’ll find it on the Kuda Business app.",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
  name: "Open a Kuda Business Account",
  illustration: (

    <Img src={subPackageIllustration} className="kuda-business-image" />

  ),
}


const questions = [
  {
    heading: "What TV subscriptions can I pay on Kuda Business?",
    list: (
      <span className="flex flex-column  mt-2">

        <span className="faq-description f-16">You can pay DStv subscriptions, Showmax subscriptions,
          GOtv subscriptions and StarTimes subscriptions on the Kuda Business mobile and web apps.</span>
      </span>),

    index: 1
  },
  {
    heading: "How do I get my token when I pay a Showmax subscription on the Kuda Business mobile app?",
    list: (
      <span className="flex flex-column  mt-2">

        <span className="faq-description f-16">Tap the Showmax payment in your transaction history on the
          Kuda Business mobile app to get your token. You can also check your email.</span>
      </span>),

    index: 2
  },
  {
    heading: "How do I pay a TV subscription on Kuda Business?",
    list: (
      <span className="flex flex-column  mt-2">
        <span className="faq-description f-16">To pay a DStv, Showmax, GOtv or StarTimes subscription on the Kuda Business mobile or web app, follow the steps below:</span>



        <span className="pt-3 career-message-margin-bottom ">
          <div className="flex flex-column  leading-md-6 f-md-16">
            <div className="mb-3">1. Sign in to the Kuda Business web or mobile app, then tap  <b>Payments.</b></div>
            <div className="mb-3">2. Tap <b>Pay A Bill.</b></div>
            <div className="mb-3">3. Tap <b>TV.</b></div>
            <div className="mb-3">4. Tap the service provider dropdown menu, then choose a service provider.</div>
            <div className="mb-3">5. Tap the subscription package dropdown menu, then choose a subscription package.</div>
            <div className="mb-3">6. If you're paying a DStv, GOtv or Startimes subscription, type in your smart card number.</div>
            <div className="mb-3">7. Confirm your subscription with your transaction PIN, fingerprint or Face ID.</div>
            <div className="mb-3">8. Tap Okay to return to your Kuda Business dashboard.</div>
          </div>
        </span>
        <span>Note: <br />< br />
          If you pay a Showmax subscription, tap the payment in your transaction history on the Kuda app to get your token or check your email.  <br /> <br />
          If you pay a DStv, GOtv or StarTimes subscription, your subscription should be active in minutes.
        </span>
      </span>),
    index: 3
  },

]

const moreForYou = [
  {
    icon: <KudaPOSTerminalIcon />,
    title: "POS",
    subText: `Buy a physical POS, lease it for a 
    one-time fee, or get a virtual POS to accept payments quickly.`,
    linkTo: `/en-ng/business/pos/`
  },

  {
    icon: <InvoicingIcon />,
    title: "Invoicing",
    subText: `Create and manage invoices easily, and sync your product catalogue for accurate pricing.`,
    linkTo: `/en-ng/business/invoicing/`
  },
  {
    icon: <BizSalaryLoanIcon />,
    title: "Salary Loan",
    subText: `Offer your staff loans with easy application, approval and disbursement through Kuda Business.`,
    linkTo: `/en-ng/business/salary-loan/`
  },

]

const TV = () => {
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])

  return (

    <Fragment>

      <InnerPageHeader
        title={entryContent.title}
        subtitle={entryContent.subtitle}
        buttonName={entryContent.name}
        buttonUrl={entryContent.url}
        entryIllustration={EntryIllustration}
        fallback={<EntryIllustrationBlur/>}
      />
      <DarkBelowFoldCards topFeatures={businessTopFeatures} />
      <ImageDarkTextLeftWithButton
        title={serviceProvider.title}
        subtitle={serviceProvider.subtitle}
        illustration={serviceProvider.illustration}
        name={serviceProvider.name}
        url={serviceProvider.url}
        isExternal={true}
      />
      <ImageDarkTextRightWithButton
        title={tvSubcription.title}
        subtitle={tvSubcription.subtitle}
        illustration={tvSubcription.illustration}
        name={tvSubcription.name}
        url={tvSubcription.url}
        isExternal={true}
      />
      <ImageDarkTextLeftWithButton
        title={subPackage.title}
        subtitle={subPackage.subtitle}
        illustration={subPackage.illustration}
        name={subPackage.name}
        url={subPackage.url}
        isExternal={true}
      />

      <FAQList title={"TV Subscription FAQs"} questions={questions} />
      <MoreForYouComponent moreForYou={moreForYou} />
      <CTABusiness />
    </Fragment>
  )
}

export default TV

