import React from "react"
import TV from "../../../../components/body/pages/en-ng/business/bill-payments/tv"
import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"

const TVPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/business/bill-payments/cable-tv"}
      title="Cable TV Payments | Kuda for Business"
      description="Simplify your business's cable TV payments with Kuda. Enjoy uninterrupted access to entertainment for your customers or employees. Join Kuda!
            Overview of how cable tv payments work through the Kuda for Business app"
    />
    <TV />
  </Layout>
)

export default TVPage
